'use client'

import { Suspense } from 'react'
import posthog from 'posthog-js'
import { PostHogProvider as PostHogProviderBase } from 'posthog-js/react'
import PostHogPageView from './components/PostHogPageView/PostHogPageView'
import PostHogUserTracker from './components/PostHogUserTracker/PostHogUserTracker'

if (__CLIENT__) {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY!, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
    person_profiles: 'identified_only',
    capture_pageview: false, // Disable automatic pageview capture, as we capture manually
  })
}

export default function PostHogProvider({ children }: { children: React.ReactNode }) {
  return (
    <PostHogProviderBase client={posthog}>
      <Suspense fallback={null}>
        <PostHogPageView />
      </Suspense>
      <PostHogUserTracker />
      {children}
    </PostHogProviderBase>
  )
}
