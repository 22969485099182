'use client'

import { usePostHog } from 'posthog-js/react'
import { useProfileMe } from 'hooks'
import { useEffect } from 'react'

export default function PostHogUserTracker() {
  const posthog = usePostHog()
  const { data } = useProfileMe()
  // const { address } = useAuthContext()

  useEffect(() => {
    if (data?.id && posthog) {
      posthog?.identify(data.id)
    }
  }, [posthog, data?.id])

  return null
}
