import { useQuery } from '@tanstack/react-query'
import { restApi } from 'helpers'
import { useAccount } from 'wagmi'
import { useAuthContext, authStore } from 'contexts'

export const getProfileMeApiData = async () => {
  if (!authStore.getSnapshot().token) {
    throw new Error('No token provided')
  }

  // const [{ data }, { data: creditsData }] = await Promise.all([
  //   restApi.get<UserData>('/profile/me'),
  //   restApi.get<UserCreditAllocations>('/profile/credit-allocations').catch(() => ({ data: null })),
  // ])

  const { data } = await restApi.get<UserData>('/profile/me')

  if (data.wallet.toLowerCase() !== authStore.getSnapshot().address?.toLowerCase()) {
    authStore.logout()
    return null
  }

  // return {
  //   ...data,
  //   // profit_passive: undefined,
  //   // last_profit_passive: undefined,
  // } satisfies UserData

  return data
}

const useProfileMe = () => {
  // const { address } = useAccount()
  const { address, token } = useAuthContext()

  return useQuery({
    queryKey: ['profile', 'me', address],
    queryFn: getProfileMeApiData,
    enabled: Boolean(__CLIENT__ && address && token),
    refetchInterval: 300000,
  })
}

export default useProfileMe
